<template>
    <div v-if="featureReport">
        <section class="px-4 relative top-20 pb-32 md:pb-40 md:pt-20 pt-12">
            <div class="container">
                <!--<h2 class="font-bold text-agra-green-dark font-montserrat mb-10 lg:mb-12 text-2xl md:text-4xl max-w-[800px] w-full">
                    {{ featureReport.titleLong }}
                </h2>-->
                <div class="pb-12 lg:pb-20">
                    <div class="relative">
                        <div class="absolute w-full h-full -inset-1.5 bg-black bg-opacity-10 blur-md"></div>
                        <Report size="large" :report="featureReport" actions="true"></Report>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Report from './shared/Report';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters(['featureReport']),
        },
        components: {
            Report
        },
        watch: {
            filters: {
                deep: true,
                handler: 'onDataChange'
            }
        },
        methods: {
            ...mapActions(['fetchReports'])
        },
        async mounted() {
            await this.fetchReports();
        }
    }
</script>
